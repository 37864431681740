<template>
  <Modal addClass="modal-livetv modal-tv" :showLoader="showLoader">
    <ModalHeaderTv
      v-if="canPlay"
      :asset="asset"
      :live="asset.status === 'PRESENTE'"
      @play="onPlay"
      :recordOptions="recordOptions"
      @start-recording="onStartRecording"
      @stop-recording="onStopRecording"
    />
    <ModalHeaderTv
      v-else
      :asset="asset"
      :recordOptions="recordOptions"
      @start-recording="onStartRecording"
      @stop-recording="onStopRecording"
    />

    <div
      v-if="!asset.isFakeProgram"
      class="modal-bottom fade"
      :class="{ show: showBottom }"
    >
      <div class="modal-bottom-controls" v-if="asset.channel">
        <h6>
          Mirá la programación de <span>{{ asset.channel.name }}</span> para
        </h6>

        <SelectModal
          :title="period"
          :list="['Ayer', 'Hoy', 'Mañana', 'Pasado']"
          @select="onSelectTime(asset.channel.id, $event)"
        />
      </div>

      <div class="view-render" v-if="showBottom">
        <template v-if="moretvshows.length > 0">
          <Slider :data="moretvshows" :showactive="true">
            <template slot-scope="itemslider">
              <CardTV
                v-for="(item, index) of itemslider.data"
                :key="item.idAsset"
                :data="item"
                :showLive="true"
                :sliderId="'slider-' + period"
                :sliderTitle="period"
                :cardId="index"
              />
            </template>
          </Slider>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "modalTV",

  components: {
    Modal: () => import("@/components/Modal.vue"),
    ModalHeaderTv: () => import("@/components/ModalHeaderTv.vue"),
    SelectModal: () => import("@/components/SelectModal.vue"),
    Slider: () => import("@/components/Slider.vue"),
    CardTV: () => import("@/components/Card-TV.vue"),
  },

  inject: ["modalConfirmShow"],

  data() {
    return {
      asset: {},
      period: "",
      moretvshows: [],
      showBottom: false,
      showLoader: true,
      recordOptions: tplay.recordOptionsDefault(),
      componentOrigin: undefined,
    };
  },

  computed: {
    canPlay() {
      var channelListed =
        this.asset.type !== "RECORD" ||
        (this.asset.channel && this.asset.channel.listed);
      var statusOk =
        ["FUTURO", "Planned", ""].indexOf(this.asset.status) === -1;

      return channelListed && statusOk;
    },

    recordStatus() {
      if (this.asset.recordData != undefined) {
        return this.asset.recordData;
      } else {
        return this.asset.status;
      }
    },
  },

  mounted() {
    const self = this;

    window.addEventListener(
      "click",
      function (e) {
        if (
          (e.target.className &&
            e.target.className.indexOf("excludeDropdown") !== -1) ||
          (e.target.parentNode &&
            e.target.parentNode.className &&
            e.target.parentNode.className.indexOf("excludeDropdown") !== -1)
        ) {
          return;
        }

        self.recordOptions.show = false;
      },
      false
    );

    this.$bus.$on("modal-tv:show", this.show);
  },

  beforeDestroy() {
    this.$bus.$off("modal-tv:show", this.show);
  },

  methods: {
    show(data) {
      const self = this;
      const asset = data.data;

      if (isOpened()) {
        //console.log('ya se encuentra abierto');
        self.showDetail(asset);
      } else {
        tplay.resetVueData(self);

        self.componentOrigin = data.componentOrigin;
        showModal(".modal-tv", function () {
          self.showDetail(asset);
        });
      }

      /*if(asset.type === 'RECORD' || !asset.channel.dvbTriplet) {
				showModal('.modal-tv', function() {
					self.showDetail(asset);
				});
			} else {
				//Empiezo a cargar el programa inmediatamente, mientras en paralelo abro el modal
				telecentro.tplay.core.helpers.join(
					__.partial(showModal, '.modal-tv'),
					__.partial(telecentro.tplay.core.epg.obtenerPrograma, asset.channel.dvbTriplet, asset.idAsset)
				)(function(rShowModal, rObtenerPrograma) {
					self.showDetail(rObtenerPrograma);
				});
			}*/
    },

    showDetail(asset) {
      const self = this;

      self.asset = asset;
      self.showBottom = false;
      self.showLoader = false;

      self.asset.title = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.title
      );
      self.asset.synopsis = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.synopsis
      );

      if (typeof asset.grabado !== "function") {
        self.recordOptions.grabado = false;
        console.warn('El campo "grabado" no es una función.');
      } else {
        self.recordOptions.grabado = asset.grabado();
      }

      if (gtag) {
        gtag("event", "Detalle programa", {
          event_category: "MODAL-TV",
          event_action: "Detalle programa",
          event_label: self.asset.name,
          value: "0",
        });
      }

      if (self.asset && self.asset.channel) {
        self.onSelectTime(self.asset.channel.id, "Hoy");
        setTimeout(function () {
          self.showBottom = true;
        }, 100);
      }
    },

    onPlay() {
      const self = this;

      if (gtag) {
        gtag("event", "Reproducir desde modal", {
          event_category: "MODAL-TV",
          event_action: "Reproducir desde modal",

          event_label: "[ " + this.asset.channel.name + " ] " + this.asset.name,
          value: "0",
        });
      }

      this.$bus.$emit("player:set-source", this.asset);
      tplay.sendAudience(this.asset, self.componentOrigin);
    },

    onStopRecording() {
      const self = this;

      this.modalConfirmShow({
        type: "confirm",
        classType: "danger",
        title: "Confirmá esta acción",
        acceptText:
          `Sí, ` + (this.recordStatus != "Recorded" ? "Cancelar" : "Eliminar"),
        cancelText: "No",
        content:
          `<p>Vas a ` +
          (this.recordStatus != "Recorded" ? "cancelar" : "eliminar") +
          ` la grabación de "` +
          self.asset.title +
          `". ¿Estás seguro?</p>`,

        confirm: function (ok) {
          if (ok) {
            tplay.stopRecording(self.asset, self.recordOptions, self.$bus);
          }
        },
      });

      let butttonClose = document.querySelector(".close");
      butttonClose?.click();
    },

    onStartRecording() {
      tplay.startRecording(this.asset, this.recordOptions, this.$bus);
    },

    onSelectTime(channelID, period) {
      const self = this;

      self.moretvshows = [];
      self.period = period;

      const pushShows = function (shows) {
        Array.prototype.push.apply(self.moretvshows, shows);
        self.$forceUpdate(); //Vue no es reactivo a los push con apply
      };

      if (period === "Hoy") {
        telecentro.tplay.core.epg.obtenerProgramacionHoy(
          channelID,
          false,
          pushShows
        );
      } else if (period === "Ayer") {
        telecentro.tplay.core.epg.obtenerProgramacionAyer(
          channelID,
          false,
          pushShows
        );
      } else if (period === "Mañana") {
        telecentro.tplay.core.epg.obtenerProgramacionManiana(
          channelID,
          pushShows
        );
      } else if (period === "Pasado") {
        telecentro.tplay.core.epg.obtenerProgramacionPasadoManiana(
          channelID,
          pushShows
        );
      }
    },
  },
};
</script>
